import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide39/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide39/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide39/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide39/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide39/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo establecer un protocolo de atención al cliente
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Para el 86% de los clientes, un buen servicio al cliente transforma a los
      clientes ocasionales en defensores a largo plazo de la marca. Para
      asegurar una entrega constante de soporte al cliente de alta calidad, es
      crucial contar con protocolos bien definidos.{" "}
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    En este contexto, tener protocolos claros asegura que tu equipo maneje
    eficientemente la situación, brindando respuestas rápidas y soluciones
    efectivas que, a su vez, fortalecen la lealtad del cliente a largo plazo.
    <br />
    <br />
    Recuerda que, en la era digital, la rapidez en la atención al cliente es
    esencial. Implementar protocolos específicos para responder a consultas en
    redes sociales, por ejemplo, puede marcar la diferencia entre retener a un
    cliente satisfecho y perder una oportunidad de negocio.{" "}
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Qué es un protocolo de atención al cliente.",
      },
      {
        key: 2,
        text: "Beneficios de un protocolo de atención al cliente.",
      },
      {
        key: 3,
        text: "Qué debe incluir un protocolo de atención al cliente.",
      },
      {
        key: 4,
        text: "Plantilla de un protocolo de atención al cliente.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
