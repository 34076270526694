import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide39/dataPageGuide39"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
      Cómo establecer un protocolo de atención al cliente
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/protocolo-atencion-cliente/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Implementa correctamente un protocolo de atención dentro de tu empresa para ofrecer un servicio de alta calidad."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo establecer un protocolo de atención al cliente"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/protocolo-atencion-cliente/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia39.jpg`}
      />
      <meta
        property="og:description"
        content="Implementa correctamente un protocolo de atención dentro de tu empresa para ofrecer un servicio de alta calidad."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Atención al cliente"
      location={location}
      typeGuide="guide39"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
